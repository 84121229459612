<style lang="scss" scoped>
.alert-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgba(black, 0.7);
  .alert-card {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    background-color: $primary;
  }
}
</style>

<template>
  <transition
    @before-enter="fadeBeforeEnter"
    @enter="fadeEnter"
    @leave="fadeLeave"
  >
    <div class="alert-wrapper" v-show="$store.getters['email/getMessage']">
      <div class="alert-card">{{ $store.getters["email/getMessage"] }}</div>
    </div>
  </transition>
  <header class="modal-header">
    <h1 class="modal-title">Contactez-nous</h1>
    <!--    <p class="modal-subtitle"></p>-->
  </header>
  <main class="modal-content">
    <contact-form></contact-form>
  </main>
  <footer class="modal-footer" @click="$router.go(-1)">
    <span>Fermer</span>
  </footer>
</template>

<script>
import { fade } from "@/js/gsap-transitions-hooks";

import ContactForm from "@/components/ContactForm";

export default {
  name: "Contact",
  components: {
    ContactForm
  },
  methods: {
    ...fade
  }
};
</script>
